import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M21 10.75c3.472 0 3.883.014 5.254.076 1.267.059 1.956.27 2.414.443.565.208 1.075.54 1.495.972.432.42.764.93.973 1.496.177.457.39 1.146.443 2.413.063 1.372.076 1.783.076 5.254 0 3.472-.013 3.883-.076 5.253-.058 1.268-.27 1.957-.443 2.415a4.307 4.307 0 0 1-2.468 2.474c-.458.179-1.147.391-2.414.449-1.371.062-1.782.075-5.254.075-3.471 0-3.882-.013-5.254-.075-1.267-.058-1.955-.27-2.413-.449a4.309 4.309 0 0 1-2.468-2.468c-.178-.459-.39-1.147-.443-2.415-.063-1.37-.076-1.782-.076-5.253 0-3.47.013-3.883.076-5.254.058-1.268.27-1.957.443-2.414a4.025 4.025 0 0 1 .973-1.496c.42-.431.93-.764 1.495-.972.458-.178 1.146-.39 2.413-.442 1.372-.062 1.783-.076 5.254-.076v-.005Zm0-2.336c-3.531 0-3.973.014-5.36.078-1.387.065-2.325.283-3.156.605a6.37 6.37 0 0 0-2.303 1.499 6.37 6.37 0 0 0-1.504 2.297c-.317.83-.537 1.772-.6 3.154C8.014 17.43 8 17.876 8 21.407c0 3.532.015 3.974.078 5.36.063 1.386.283 2.325.605 3.156a6.37 6.37 0 0 0 1.499 2.303 6.373 6.373 0 0 0 2.303 1.5c.827.321 1.77.542 3.156.605 1.385.063 1.829.077 5.36.077 3.532 0 3.974-.014 5.361-.077 1.387-.063 2.325-.284 3.155-.605a6.653 6.653 0 0 0 3.803-3.803c.321-.827.541-1.771.604-3.156.064-1.385.078-1.829.078-5.36 0-3.53-.014-3.974-.078-5.36-.063-1.386-.283-2.325-.604-3.156a6.375 6.375 0 0 0-1.503-2.3 6.352 6.352 0 0 0-2.303-1.5c-.827-.322-1.772-.541-3.155-.604-1.384-.063-1.827-.079-5.358-.079v.006Zm0 6.319a6.677 6.677 0 1 0-.002 13.354A6.677 6.677 0 0 0 21 14.733Zm0 11.01a4.334 4.334 0 1 1-.002-8.669A4.334 4.334 0 0 1 21 25.743Zm8.238-10.406a1.561 1.561 0 1 0-2.598-1.733 1.561 1.561 0 0 0 2.598 1.733Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconInstagram'

export const IconInstagram = memo<IconProps>(themed(Icon))
