import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M8.333 16.667v-5h3.334v5h4.166V10h2.5L10 2.5 1.667 10h2.5v6.667h4.166Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconHome'

export const IconHome = memo<IconProps>(themed(Icon))
