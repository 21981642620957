import * as Sentry from '@sentry/browser'

export function logError(e, query?: string, variables?: any) {
  const error = e as any
  // handle graphql errors
  if (error.response?.errors) {
    const err = new Error(error.response.errors[0].message)
    Sentry.addBreadcrumb({
      category: 'graphql',
      data: {
        query,
        variables,
        error: error.response.errors,
      },
    })
    Sentry.captureException(err)
    throw err
  } else {
    Sentry.captureException(e)
    throw e
  }
}
