import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="m13.067 4.264-6.633 6.632-.854.854H21.5v1.5H5.582l.852.853 6.622 6.634L12 21.793 2.707 12.5l9.294-9.294 1.066 1.058Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconArrowLeft'

export const IconArrowLeft = memo<IconProps>(themed(Icon))
