import config from 'app/config'
import makeSiteGraphqlRequest from 'app/lib/graphql-request'
import { useStores } from 'app/store'
import { UserStore } from 'app/store/user-store'
import { useQuery as useQueryBase, UseQueryOptions } from 'react-query'
import { logError } from 'app/lib/log-error'

export async function makeQuery<T>(
  query: string,
  variables: any,
  formatFn?: (data: any) => T,
  userStore?: UserStore,
  jwtToken?: string
) {
  try {
    return await makeSiteGraphqlRequest(config, query, variables, {
      userStore,
      formatFn,
      jwtToken,
    })
  } catch (e) {
    console.error(e)
    logError(e, query, variables)
  }
}

interface UseQueryOptionsWithToken<T> extends UseQueryOptions<T> {
  jwtToken?: string
}

export default function useQuery<T>(
  query: string,
  variables?: any,
  formatFn?: (data: any) => T,
  options?: UseQueryOptionsWithToken<T>
) {
  const { userStore } = useStores()
  async function fetchData() {
    if (!userStore && !options?.jwtToken) return
    return makeQuery(query, variables, formatFn, userStore, options?.jwtToken)
  }

  const { enabled: _enabled, ...rest } = options || {}
  const enabled = options?.hasOwnProperty('enabled') ? options.enabled : true

  const { data, isLoading, error, refetch } = useQueryBase(
    ['query', query, variables],
    fetchData,
    {
      enabled: !!userStore && enabled,
      ...rest,
    }
  )

  return {
    data,
    loading: isLoading,
    error: error as Error,
    fetchData: refetch,
  }
}
