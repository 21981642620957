import {
  isWeb,
  Square,
  Stack,
  XStack,
  XStackProps,
  YStack,
  SizableText,
} from '@mythical/ui'
import { Avatar } from 'app/components/primitive/Avatar'
import {
  Button,
  ButtonLink,
  ButtonLinkProps,
} from 'app/components/primitive/Button'
import FullScreenModal from 'app/components/primitive/FullScreenModal'
import {
  IconMenu,
  IconSearch,
  IconClose,
  IconChevronLeft,
} from 'app/components/primitive/Icon'
import { SrOnly } from 'app/components/primitive/SrOnly'
import { useScrollLock } from 'app/hooks/use-scroll-lock'
import useAnalytics from 'app/lib/analytics'
import { useStores } from 'app/store'
import { isIOS } from 'app/utils/constants'
import React, { useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { LinkCore } from 'solito/link'
import { useRouter } from 'solito/router'

import { Logo } from './Logo'
import Sidebar from './Sidebar'

interface NavigationProps {
  loggedIn?: boolean
  loginLink?: string
  user?: {
    profileImage?: string
    tier?: string
  }
}
interface NavLinkProps {
  href: string
  label?: string
  children?: React.ReactNode
  noBackground?: boolean
}

const NavLink = ({
  href,
  children,
  label,
  noBackground,
  ...props
}: NavLinkProps & ButtonLinkProps) => {
  const noBgStyles = { bg: '$background', borderColor: '$background' }
  return (
    <ButtonLink
      theme="secondary9"
      size="$3"
      py="$4"
      $gtXs={{ py: '$4.5' }}
      href={href}
      icon={children}
      {...(noBackground && {
        hoverStyle: noBgStyles,
        pressStyle: noBgStyles,
        focusStyle: noBgStyles,
      })}
      {...props}
    >
      {label && label}
    </ButtonLink>
  )
}

const DesktopNavigation = ({
  loggedIn,
  loginLink,
  user,
  ...props
}: NavigationProps & XStackProps) => {
  return (
    <XStack ai="center" {...props}>
      {loggedIn ? (
        <XStack ai="center" space="$1">
          {isWeb && (
            <NavLink href="/search" accessibilityLabel="Open search">
              <IconSearch size={24} color="$secondary5" />
            </NavLink>
          )}
          <NavLink href="/user/profile" mr="$2" px="$2" noBackground top="$-1">
            <Avatar
              py="$1"
              width={40}
              height={40}
              src={user?.profileImage}
              tier={user?.tier}
            />
          </NavLink>
          {user?.tier !== '3rd Degree' && !isIOS && (
            <ButtonLink href="/perks">Upgrade for more</ButtonLink>
          )}
        </XStack>
      ) : (
        <XStack ai="center" space="$2">
          <Stack ai="center" fd="row">
            {!isIOS && <NavLink href="/perks" label="Perks" />}
            <NavLink href="/pages/faqs" label="FAQ" />
            <NavLink href="/contact" label="Contact" />
          </Stack>
          <XStack space="$3" ai="center">
            {!isIOS && <ButtonLink href="/perks">Sign up</ButtonLink>}
            {loginLink && (
              <ButtonLink
                auth
                theme="outline"
                href={loginLink}
                target="_self"
                transparent
              >
                Log in
              </ButtonLink>
            )}
          </XStack>
        </XStack>
      )}
    </XStack>
  )
}

interface MobileNavigationProps extends NavigationProps {
  showMenu?: boolean
  handleMenuToggle: (isOpen: boolean) => void
}

const MobileNavigation = ({
  loggedIn,
  showMenu,
  handleMenuToggle,
  user,
  loginLink,
  ...props
}: MobileNavigationProps & XStackProps) => {
  return (
    <XStack overflow="visible" alignItems="center" space="$3" {...props}>
      {!loggedIn && (
        <XStack alignItems="center" gap="$2.5">
          {!isIOS && (
            <ButtonLink size="$3" href="/perks">
              Sign up
            </ButtonLink>
          )}
          {loginLink && (
            <ButtonLink
              auth
              size="$3"
              theme="outline"
              href={loginLink}
              target="_self"
              transparent
            >
              Log in
            </ButtonLink>
          )}
        </XStack>
      )}
      <Stack zIndex="$6">
        <Button
          onPress={() => handleMenuToggle(!showMenu)}
          px="$1"
          theme="secondary9"
          accessibilityLabel="Toggle menu"
        >
          {showMenu ? (
            <IconClose size={34} color="$secondary1" />
          ) : (
            <IconMenu size={34} color="$secondary1" />
          )}
        </Button>
      </Stack>
    </XStack>
  )
}

interface MainNavigationProps {
  showBackBtn?: boolean
  onBack?: () => void
}

const Navigation = ({ showBackBtn, onBack }: MainNavigationProps) => {
  const { userStore } = useStores()
  const [showMenu, setShowMenu] = React.useState(false)
  const loggedIn = !!userStore?.user?.id
  const loginLink = userStore?.generateLoginUrl()
  const { lockScroll, unlockScroll } = useScrollLock()
  const userProps = {
    profileImage: userStore?.user?.profileImage,
    tier: userStore?.user?.tier,
  }
  const analytics = useAnalytics()
  const router = useRouter()

  useEffect(() => {
    if (typeof window === 'undefined') return

    if (!userStore?.user?.id) {
      analytics.logUser(null)
    } else {
      analytics.logUser(userStore?.user?.id, userStore?.user?.tier || 'free')
    }
  }, [userStore?.user?.id])

  const handleMenuToggle = (isOpen: boolean) => {
    if (isOpen) {
      lockScroll()
    } else {
      unlockScroll()
    }
    setShowMenu(isOpen)
  }

  return (
    <>
      {!isWeb && (
        <Square
          h={60}
          w="100%"
          top={0}
          left={0}
          position="absolute"
          bg="$secondary9"
        />
      )}
      <SafeAreaView
        style={{
          zIndex: 1000,
          position: isWeb ? ('fixed' as 'absolute') : 'relative',
          width: '100%',
        }}
      >
        <XStack
          fd="row"
          py="$3"
          px="$3"
          minHeight={64}
          $gtMd={{
            px: '$6',
          }}
          ai="center"
          bg="$secondary9"
          pos="relative"
          jc="space-between"
        >
          <LinkCore
            href={isWeb && loggedIn ? '/homescreen' : '/'}
            as="/"
            Component={YStack}
            componentProps={{
              tag: 'a',
              pr: '$2',
              scale: 1,
              hoverStyle: {
                scale: 1.01,
              },
              pressStyle: {
                scale: 0.97,
              },
              ...(showBackBtn && {
                $xs: {
                  display: 'none',
                },
              }),
              onPress: () => {
                if (isWeb) return
                router.push('/home')
              },
            }}
          >
            <Logo />
            {isWeb && <SrOnly>Back to homepage</SrOnly>}
          </LinkCore>

          {showBackBtn && (
            <Button
              theme="transparent"
              onPress={onBack || router.back}
              px="$2"
              $gtXs={{ display: 'none' }}
              uppercase={false}
              icon={
                <IconChevronLeft
                  size={34}
                  color="$primary1"
                  style={{ left: -12, position: 'relative' }}
                />
              }
              pressStyle={{
                opacity: 0.7,
              }}
            >
              <SizableText pos="relative" left={-20}>
                Back
              </SizableText>
            </Button>
          )}

          <DesktopNavigation
            display="none"
            $gtMd={{
              display: 'flex',
            }}
            loggedIn={loggedIn}
            loginLink={isWeb ? loginLink : '/login'}
            user={userProps}
          />
          <MobileNavigation
            showMenu={showMenu}
            handleMenuToggle={() => handleMenuToggle(!showMenu)}
            loggedIn={loggedIn}
            user={userProps}
            loginLink={isWeb ? loginLink : '/login'}
            $gtMd={{
              display: 'none',
            }}
          />
        </XStack>
        {showMenu && (
          <FullScreenModal
            containerProps={{
              bg: '$secondary9',
            }}
            $gtMd={{
              display: 'none',
            }}
          >
            <Sidebar
              h="100%"
              px="$3"
              maxWidth="80%"
              minWidth={300}
              mx="auto"
              onNavigate={() => handleMenuToggle(false)}
              $gtSm={{
                px: '$6',
              }}
            />
          </FullScreenModal>
        )}
      </SafeAreaView>
    </>
  )
}

export default Navigation
