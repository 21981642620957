import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M33.459 15.15a3.253 3.253 0 0 0-2.3-2.298C29.132 12.308 21 12.308 21 12.308s-8.131 0-10.158.544a3.25 3.25 0 0 0-2.299 2.299C8 17.177 8 21.408 8 21.408s0 4.232.543 6.26a3.256 3.256 0 0 0 2.299 2.297C12.869 30.51 21 30.51 21 30.51s8.131 0 10.16-.544a3.259 3.259 0 0 0 2.299-2.298C34 25.64 34 21.408 34 21.408s0-4.23-.541-6.257ZM18.382 25.309v-7.804l6.757 3.9-6.756 3.904Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconYoutube'

export const IconYoutube = memo<IconProps>(themed(Icon))
