import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M18.563 3.543c-1.958 0-3.837.911-5.063 2.351-1.226-1.44-3.105-2.351-5.063-2.351-3.464 0-6.187 2.722-6.187 6.187 0 4.253 3.825 7.718 9.619 12.983l1.631 1.474 1.631-1.485c5.794-5.254 9.619-8.719 9.619-12.972 0-3.465-2.723-6.187-6.188-6.187Zm-4.95 17.494-.113.112-.113-.112C8.034 16.188 4.5 12.982 4.5 9.73c0-2.25 1.688-3.937 3.938-3.937 1.732 0 3.42 1.114 4.016 2.655h2.103c.585-1.541 2.273-2.655 4.005-2.655 2.25 0 3.938 1.687 3.938 3.937 0 3.252-3.532 6.458-8.887 11.307Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconHeart'

export const IconHeart = memo<IconProps>(themed(Icon))
