import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M27.647 13.407c-1.714-3.84-5.351-4.14-6.424-4.14h-.065l-.454.015c-1.07 0-4.696.293-6.363 4.114-.572 1.274-.441 3.443-.335 5.197l.002.043.05.616c-.088.05-.25.114-.504.114-.341 0-.74-.115-1.196-.325a1.099 1.099 0 0 0-.415-.08c-.535 0-1.168.34-1.265.86-.065.37.097.907 1.297 1.378.114.049.243.098.375.13.01.002.02.006.03.01.486.163 1.206.405 1.412.866.114.242.067.567-.128.94l-.018.032c-.066.153-1.654 3.77-5.19 4.355a.537.537 0 0 0-.455.552.651.651 0 0 0 .06.244c.258.632 1.376 1.087 3.454 1.328.05.063.1.276.143.46.013.057.025.111.037.157.018.073.034.149.05.226.028.127.055.259.096.389.065.212.227.454.632.454.161 0 .356-.033.585-.08l.077-.014c.33-.064.759-.147 1.301-.147.324 0 .665.031 1.005.08.645.113 1.191.496 1.833.946l.017.012c.923.664 1.962 1.395 3.552 1.395h.161c.034 0 .066.004.099.008.032.004.064.008.096.008 1.582 0 2.633-.738 3.554-1.384l.015-.011c.65-.455 1.216-.845 1.866-.958.34-.064.665-.08.99-.08.55 0 .989.065 1.379.146.243.047.436.08.582.08h.033c.309 0 .519-.145.601-.438.065-.21.114-.405.145-.6l.01-.043c.047-.208.109-.482.168-.574 2.03-.306 3.148-.76 3.408-1.377a.656.656 0 0 0 .05-.243.54.54 0 0 0-.456-.552c-3.53-.582-5.118-4.185-5.19-4.347v-.001l-.017-.017c-.196-.372-.227-.697-.13-.94.21-.487.957-.731 1.445-.877l.224-.078.148-.051c.892-.357 1.33-.78 1.314-1.266 0-.389-.307-.746-.795-.908a1.482 1.482 0 0 0-.551-.097c-.13 0-.324.017-.503.113-.405.196-.794.308-1.119.326a.841.841 0 0 1-.434-.098c.008-.179.02-.357.032-.552l.003-.065c.113-1.764.25-3.959-.324-5.251Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconSnapchat'

export const IconSnapchat = memo<IconProps>(themed(Icon))
