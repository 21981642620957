import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M4 24h24v-2.667H4V24Zm0-6.667h24v-2.666H4v2.666ZM4 8v2.667h24V8H4Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconMenu'

export const IconMenu = memo<IconProps>(themed(Icon))
