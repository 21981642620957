import React, { memo } from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 20, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        fill={color}
        d="M6.25 17.5H1.667v-10H6.25v10Zm6.042-15H7.708v15h4.584v-15Zm6.041 6.667H13.75V17.5h4.583V9.167Z"
      />
    </IconBase>
  )
}

Icon.displayName = 'IconLeaderboard'

export const IconLeaderboard = memo<IconProps>(themed(Icon))
