import {
  Headline,
  SizableText,
  XStack,
  YStack,
  YStackProps,
} from '@mythical/ui'
import { Avatar } from 'app/components/primitive/Avatar'
import { Tag } from 'app/components/primitive/Tag/Tag'
import useQuery from 'app/hooks/use-query'
import { createUserQuery, userQueryToUser } from 'app/queries/user/cms-user'
import { useStores } from 'app/store'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

export type UserInfoProps = object & YStackProps

export const UserInfo = observer(({ ...props }: UserInfoProps) => {
  const { userStore } = useStores()

  const { data: queriedData, fetchData } = useQuery(
    createUserQuery(),
    null,
    userQueryToUser,
    {
      enabled: !!userStore?.user,
    }
  )

  useEffect(() => {
    if (!userStore?.user) return

    fetchData()
  }, [userStore?.user])

  useEffect(() => {
    if (!queriedData) return
    if (!userStore?.user) return

    // compare two objects
    if (JSON.stringify(userStore.user) !== JSON.stringify(queriedData)) {
      userStore.setUser(queriedData)
    }
  }, [queriedData, userStore?.user])

  if (!userStore?.user) {
    return null
  }

  const data = Object.assign({}, userStore.user, queriedData || {})

  // format as MONTH DAY, YEAR
  const date = data.activatedAt
    ? format(new Date(data.activatedAt), 'MMM d, yyyy')
    : null

  return (
    <XStack w="100%" jc="center" space="$4" {...props}>
      <YStack fs={1} space="$2.5" jc="center" ai="center">
        <Avatar tier={data.tier} src={data.profileImage} width={100} height={100} />
        {data.tier !== '3rd Degree' && (
          <XStack space="$2">
            <Tag displaySize="sm" variant="secondary" uppercase={false}>
              {data.tier}
            </Tag>
          </XStack>
        )}
        <Headline textTransform="uppercase" ta='center' fontSize="$5" color="$primary3">
          {data.fullName}
        </Headline>

        {data.userPercentile && (
          <XStack space="$2">
            <Tag displaySize="sm" variant="green" uppercase>
              Top {data.userPercentile}%
            </Tag>
          </XStack>
        )}
      </YStack>
      <YStack space="$2" px="$2">
        <Stat
          label="Daily Streak"
          value={(data.dailyStreak || 0).toLocaleString()}
        />
        {data.videosWatched ? (
          <Stat label="Videos Watched" value={data.videosWatched.toLocaleString()} />
        ) : null}
        {date && <Stat label="Membership Start" value={date} />}
      </YStack>
    </XStack>
  )
})

type StatProps = { label: string; value: string }

const Stat = ({ label, value }: StatProps) => {
  return (
    <YStack space="$1">
      <SizableText textTransform="uppercase" size="$2" color="$primary8">
        {label}
      </SizableText>
      <SizableText
        fontFamily="$heading"
        size="$6"
        color="$primary3"
        fontWeight="500"
        textTransform="uppercase"
      >
        {`${value}`}
      </SizableText>
    </YStack>
  )
}
