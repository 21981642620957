import React, { memo } from 'react'
import { Path } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 20, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        fill={color}
        d="M15 5H13.3333C13.3333 3.15833 11.8417 1.66667 10 1.66667C8.15834 1.66667 6.66667 3.15833 6.66667 5H5C4.08334 5 3.33334 5.75 3.33334 6.66667V16.6667C3.33334 17.5833 4.08334 18.3333 5 18.3333H15C15.9167 18.3333 16.6667 17.5833 16.6667 16.6667V6.66667C16.6667 5.75 15.9167 5 15 5ZM8.33334 8.33333C8.33334 8.79167 7.95834 9.16667 7.5 9.16667C7.04167 9.16667 6.66667 8.79167 6.66667 8.33333V6.66667H8.33334V8.33333ZM10 3.33333C10.9167 3.33333 11.6667 4.08333 11.6667 5H8.33334C8.33334 4.08333 9.08334 3.33333 10 3.33333ZM13.3333 8.33333C13.3333 8.79167 12.9583 9.16667 12.5 9.16667C12.0417 9.16667 11.6667 8.79167 11.6667 8.33333V6.66667H13.3333V8.33333Z"
      />
    </IconBase>
  )
}

Icon.displayName = 'IconMerch'

export const IconMerch = memo<IconProps>(themed(Icon))
