import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="m25.333 8.547-1.88-1.88L16 14.12 8.547 6.667l-1.88 1.88L14.12 16l-7.453 7.453 1.88 1.88L16 17.88l7.453 7.453 1.88-1.88L17.88 16l7.453-7.453Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconClose'

export const IconClose = memo<IconProps>(themed(Icon))
