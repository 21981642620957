import React, { memo } from 'react'
import { Path } from 'react-native-svg'

import { IconBase } from '../IconBase'
import { IconProps } from '../IconProps'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'red', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm0 2.5c1.383 0 2.5 1.116 2.5 2.5 0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5c0-1.384 1.117-2.5 2.5-2.5ZM10 16a6 6 0 0 1-5-2.684c.025-1.658 3.333-2.566 5-2.566 1.658 0 4.975.908 5 2.566A6 6 0 0 1 10 16Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconUser'

export const IconUser = memo<IconProps>(themed(Icon))
