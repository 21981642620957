import React, { ComponentType } from 'react'
import { LinkCore } from 'solito/link'
import { useDidFinishSSR } from '@mythical/ui'

export type OptionalLinkProps<ComponentProps> = {
  href?: string
  Component: ComponentType<any>
  componentProps?: ComponentProps
  children: React.ReactNode
}

/**
 * Will render a link if href is provided, otherwise will render the component
 **/
export const OptionalLink = <ComponentProps extends {}>(
  props: OptionalLinkProps<ComponentProps>
) => {
  const hasMounted = useDidFinishSSR()

  if (!hasMounted) return null // Have to wait, otherwise it throws hydration errors when used with "href"

  const { href, Component, componentProps, children } = props

  if (!href) {
    return <Component {...componentProps}>{children}</Component>
  }

  return (
    <LinkCore
      href={href}
      Component={Component}
      componentProps={{ tag: 'a', ...componentProps }}
    >
      {children}
    </LinkCore>
  )
}
