import React from 'react'
import { SizableText, XStack, useDidFinishSSR, useMedia } from '@mythical/ui'
import RichText from '../RichText'
import { IconChevronRight, IconClose } from '../Icon'
import { Button } from '../Button'
import { OptionalLink } from '../OptionalLink'

export interface PromoBannerProps {
  color?: 'primary' | 'white'
  ctaLabel?: string
  ctaLink?: string
  text?: any // RichText
  onClose?: () => void
}

export const PromoBanner = (props: PromoBannerProps) => {
  const media = useMedia()
  const { color = 'primary', ctaLabel, text, ctaLink, onClose } = props

  return (
    <XStack
      borderColor="$color.primary10"
      borderBottomWidth={1}
      w="100%"
      ai="center"
      jc="center"
      overflow="hidden"
      pos="relative"
    >
      <OptionalLink
        href={ctaLink}
        Component={XStack}
        componentProps={{
          jc: 'center',
          ai: 'center',
          w: '100%',
          bg: color === 'primary' ? '$primary8' : '$white',
          py: '$2',
          minHeight: 40,
          ...(ctaLink && {
            hoverStyle: {
              opacity: 0.9,
            },
            pressStyle: {
              opacity: 0.8,
            },
          }),
        }}
      >
        <XStack
          jc="center"
          ai="center"
          maxWidth="75%"
          $gtSm={{
            maxWidth: '100%',
            px: '$12',
            fd: 'row',
            flexWrap: ctaLabel ? 'wrap' : 'nowrap',
          }}
        >
          <RichText
            content={text}
            textProps={{ textAlign: 'center', textDecorationLine: 'none' }}
            color="$secondary10"
            containerProps={{
              flexShrink: 1,
            }}
          />
          {ctaLabel && (
            <XStack
              ai="center"
              pos="relative"
              top={1}
              pl={ctaLabel ? '$4' : '$1'}
              pr={ctaLabel ? '$2' : '$1'}
            >
              {!media.xs && (
                <>
                  {ctaLabel && (
                    <SizableText
                      size="$2"
                      letterSpacing={1.2}
                      color="$secondary10"
                      textTransform="uppercase"
                    >
                      {ctaLabel}
                    </SizableText>
                  )}
                </>
              )}
              <IconChevronRight
                style={{ position: 'relative', top: 1 }}
                size={30}
                color="$secondary10"
              />
            </XStack>
          )}
        </XStack>
      </OptionalLink>
      <Button
        theme="transparent"
        pos="absolute"
        right={0}
        my="auto"
        size="$2"
        onPress={onClose}
        hoverStyle={{
          scale: 1.2,
        }}
        pressStyle={{
          scale: 1.1,
        }}
        $gtMd={{
          right: '$6',
        }}
      >
        <IconClose size={24} color="$secondary10" />
      </Button>
    </XStack>
  )
}
