import { Stack, styled, GetProps, SizableText } from '@mythical/ui'
import React from 'react'

const TagWrapper = styled(Stack, {
  px: '$2',
  br: '$1',
  variants: {
    variant: {
      primary: {
        bg: '$primary10',
        pressStyle: {
          bg: '$primary9',
        },
        hoverStyle: {
          bg: '$primary9',
        },
      },
      neutral: {
        bg: 'rgba(0, 0, 0, 0.4)',
      },
      secondary: {
        bg: '$secondary8',
        pressStyle: {
          bg: '$secondary7',
        },
        hoverStyle: {
          bg: '$secondary7',
        },
      },
      green: {
        bg: '$green6',
      },
    },
    displaySize: {
      sm: {
        px: '$2',
        py: '$1.5',
        br: '$1',
      },
      md: {
        px: '$3',
        py: '$2',
        br: '$2',
      },
      lg: {
        px: '$2.5',
        py: '$2',
        br: '$2',
      },
      xl: {
        px: '$2.5',
        py: '$2',
      },
    },
  } as const,
  defaultVariants: {
    variant: 'primary',
    displaySize: 'sm',
  },
})

const TagText = styled(SizableText, {
  textAlign: 'center',
  position: 'relative',
  fontFamily: '$body',
  textDecorationLine: 'none',
  variants: {
    variant: {
      primary: {
        color: '$secondary9',
      },
      neutral: {
        color: '$white',
      },
      secondary: {
        color: '$white',
      },
      green: {
        color: '$white',
      },
    },
    displaySize: {
      sm: {
        size: '$1',
      },
      md: {
        size: '$2',
      },
      lg: {
        size: '$3',
        $gtSm: {
          size: '$4',
        },
      },
      xl: {
        size: '$6',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
      false: {
        textTransform: 'none',
      },
    },
  } as const,
  defaultVariants: {
    displaySize: 'sm',
    uppercase: true,
  },
})

type CustomTagProps = GetProps<typeof TagWrapper>
type CustomTagTextProps = GetProps<typeof TagText>

type TagProps = {
  children: React.ReactNode
  textProps?: CustomTagTextProps
}

export type ITagProps = TagProps & CustomTagProps & CustomTagTextProps

export const Tag = React.forwardRef<HTMLDivElement, ITagProps>(
  ({ children, textProps, uppercase, displaySize, variant, ...props }, ref) => {
    const commonProps = {
      displaySize,
      variant,
    }

    return (
      <TagWrapper ref={ref} {...commonProps} {...props}>
        <TagText uppercase={uppercase} {...commonProps} {...textProps}>
          {children}
        </TagText>
      </TagWrapper>
    )
  }
)
