import { Headline, XStack, Image, XStackProps } from '@mythical/ui'
import React from 'react'
const logo = require('../../../assets/images/logo.png')

type LogoProps = object & XStackProps

export const Logo = ({ ...props }: LogoProps) => {
  return (
    <XStack ai="center" fd="row" pr="$2" space="$2.5" {...props}>
      <XStack ai="center" height={28} width={50}>
        <Image style={{ width: '100%', height: '100%' }} source={logo} />
      </XStack>
      <Headline
        display="none"
        fontSize="$5"
        color="$primary10"
        textTransform="uppercase"
        fontWeight="500"
        $gtXs={{
          fontSize: '$7',
          fontWeight: '500',
          display: 'flex',
        }}
      >
        Mythical Society
      </Headline>
    </XStack>
  )
}
