import { Text, VisuallyHidden } from '@mythical/ui'
import React from 'react'

export const SrOnly = ({ children }) => {
  return (
    <VisuallyHidden>
      <Text>{children}</Text>
    </VisuallyHidden>
  )
}
