import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconBase } from '../IconBase'
import { IconProps } from '../IconProps'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5ZM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconLogOut'

export const IconLogOut = memo<IconProps>(themed(Icon))
