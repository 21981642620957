import {
  Image,
  isWeb,
  styled,
  XStack,
  YStack,
  Stack,
  StackPropsBase,
  ScrollView,
} from '@mythical/ui'
import { secondaryColors } from '@mythical/ui-config/colors'
import { IconArrowLeft } from 'app/components/primitive/Icon/icons/IconArrowLeft'
import { KeyboardAwareScrollView } from 'app/components/primitive/KeyboardAwareScrollView'
import React, { useState } from 'react'
import { RefreshControlProps, StatusBar, StyleSheet } from 'react-native'

import Footer from '../Footer'
import Navigation from '../Navigation/MainNavigation'
import Sidebar from '../Navigation/Sidebar'
import { Button } from 'app/components/primitive/Button'
import { useRouter } from 'solito/router'
import {
  PromoBanner,
  PromoBannerProps,
} from 'app/components/primitive/PromoBanner'

interface LayoutProps {
  noHeader?: boolean
  noScroll?: boolean
  noSidebar?: boolean
  noFooter?: boolean
  noBottomTabs?: boolean
  showBackBtn?: boolean
  refreshControl?: React.ReactElement<RefreshControlProps>
  contentStyles?: StackPropsBase
  children: React.ReactNode
  onBack?: () => void
  scrollRef?: React.RefObject<KeyboardAwareScrollView>
  promoBannerProps?: PromoBannerProps
}
interface ContentWithScrollProps {
  children: React.ReactNode
  refreshControl?: React.ReactElement<RefreshControlProps>
  contentStyles?: StackPropsBase
  onBack?: () => void
  noFooter?: boolean
  showBackBtn?: boolean
  scrollRef?: React.RefObject<KeyboardAwareScrollView>
}
interface ContentProps {
  contentStyles?: StackPropsBase
  showBackBtn?: boolean
  children: React.ReactNode
  onBack?: () => void
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    width: '100%',
  },
})

const ContentWrapper = styled(YStack, {
  w: '100%',
  flexGrow: 1,
  mx: 'auto',
  px: '$2',
  pb: '$3',
  $gtXs: {
    px: '$3',
    pb: '$4',
  },
  $gtMd: {
    px: '$6',
    pb: '$6',
  },
  variants: {
    withBackBtn: {
      true: {
        pt: '$3',
        $gtSm: {
          pt: '$4',
        },
      },
      false: {
        pt: '$3',
        $gtXs: {
          pt: '$4',
        },
        $gtMd: {
          pt: '$6',
        },
      },
    },
  },
})

const Content: React.FC<ContentProps> = ({
  children,
  contentStyles,
  onBack,
  showBackBtn = false,
}) => {
  const router = useRouter()
  return (
    <ContentWrapper
      {...(isWeb && { maxWidth: 1440 })}
      withBackBtn={showBackBtn}
    >
      {showBackBtn && (
        <XStack jc="flex-start" ai="center">
          <Button
            theme="transparent"
            fontSize="$4"
            fontWeight="600"
            px="$0"
            icon={<IconArrowLeft size={20} color="$primary1" />}
            onPress={onBack || router.back}
            $xs={{ display: 'none' }}
            uppercase={false}
          >
            Back
          </Button>
        </XStack>
      )}
      <YStack
        minWidth="100%"
        maxWidth="100%"
        borderRadius="$3"
        elevation={12}
        overflow="hidden"
        {...contentStyles}
      >
        {children}
      </YStack>
    </ContentWrapper>
  )
}

const ContentWithoutScroll: React.FC<ContentWithScrollProps> = ({
  children,
  contentStyles,
  onBack,
  noFooter,
  showBackBtn,
}) => {
  return (
    <YStack flexGrow={1} flexShrink={1} width="100%">
      <Content
        onBack={onBack}
        showBackBtn={showBackBtn}
        contentStyles={contentStyles}
      >
        {children}
      </Content>
      {!noFooter && <Footer />}
    </YStack>
  )
}

const ContentWithScroll: React.FC<ContentWithScrollProps> = ({
  children,
  refreshControl,
  contentStyles,
  onBack,
  showBackBtn,
  noFooter,
  scrollRef,
}) => {
  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      extraScrollHeight={100}
      contentContainerStyle={styles.contentContainer}
      bounces
      showsVerticalScrollIndicator
      refreshControl={refreshControl}
      nestedScrollEnabled
      ref={scrollRef}
      nativeID="scroll-container"
    >
      <Content
        onBack={onBack}
        showBackBtn={showBackBtn}
        contentStyles={contentStyles}
      >
        {children}
      </Content>
      {!noFooter && <Footer />}
    </KeyboardAwareScrollView>
  )
}

const MainLayout: React.FC<LayoutProps> = ({
  children,
  refreshControl,
  contentStyles,
  onBack,
  showBackBtn,
  noSidebar,
  noFooter = true,
  noScroll,
  noHeader,
  noBottomTabs,
  scrollRef,
  promoBannerProps,
}) => {
  const ContentComp = noScroll ? ContentWithoutScroll : ContentWithScroll
  const [displayPromo, setDisplayPromo] = useState(true)
  return (
    <Stack
      zIndex={0}
      bg="$secondary8"
      // Compensate for bottom Nav
      {...((!noBottomTabs && !isWeb) && { $md: { pb: '$11' }})}
      {...(isWeb && { minHeight: '100vh' })}
    >
      <StatusBar
        backgroundColor={secondaryColors.secondary9}
        barStyle="light-content"
      />
      <Stack width="100%" height="100%" position="absolute" opacity={0.1}>
        <Image
          position="absolute"
          width="100%"
          height="100%"
          source={require('../../../assets/images/pattern-green.png')}
          resizeMode="repeat"
          resizeMethod="scale"
          zIndex={0}
        />
      </Stack>

      {!noHeader && isWeb && (
        <Navigation showBackBtn={showBackBtn} onBack={onBack} />
      )}

      <YStack
        height="100%"
        pt={isWeb ? '70px' : '$0'} // Nav
        {...(isWeb && { minHeight: '100vh' })}
        $gtMd={{
          ...(isWeb && {
            maxHeight: 'calc(100vh - 70px)',
            overflowY: 'hidden',
          }),
        }}
      >
        {displayPromo && promoBannerProps?.text && (
          <PromoBanner
            onClose={() => setDisplayPromo(false)}
            {...promoBannerProps}
          />
        )}
        <XStack flex={1} h="100%">
          {!noSidebar && (
            <YStack
              bg="$secondary10"
              maxWidth={330}
              display="none"
              $gtMd={{
                display: 'flex',
              }}
            >
              <ScrollView
                px="$6"
                showsVerticalScrollIndicator={isWeb}
                contentContainerStyle={{ flexGrow: 1 }}
              >
                <Sidebar height="100%" buttonTheme="secondary10" />
              </ScrollView>
            </YStack>
          )}

          <ContentComp
            refreshControl={refreshControl}
            contentStyles={contentStyles}
            onBack={onBack}
            showBackBtn={showBackBtn}
            noFooter={noFooter}
            scrollRef={scrollRef}
          >
            {children}
          </ContentComp>
        </XStack>
      </YStack>
    </Stack>
  )
}

export default MainLayout
