import React, { memo } from 'react'
import { Path, Circle as _Circle } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M17.336 8.504 15.75 6.918 9 13.668l6.75 6.75 1.586-1.586-5.152-5.164 5.152-5.164Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconChevronLeft'

export const IconChevronLeft = memo<IconProps>(themed(Icon))
