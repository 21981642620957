import React, { memo } from 'react'
import { Path, Circle as _Circle, Rect } from 'react-native-svg'

import { IconBase } from '../IconBase'
import { IconProps } from '../IconProps'
import { themed } from '../themed'

const Icon = (props) => {
  const { size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 42 43"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M34 21.488a13.143 13.143 0 0 1-3.118 8.499 12.967 12.967 0 0 1-7.852 4.421v-9.134h3.027l.58-3.786H23.03v-2.454c0-.13.009-.258.026-.385.099-.745.51-1.41 1.477-1.604.202-.04.409-.06.615-.059h1.64v-3.213a19.92 19.92 0 0 0-2.914-.255c-.44-.002-.88.042-1.312.132-1.763.374-3 1.541-3.427 3.395a7.47 7.47 0 0 0-.161 1.552v2.889h-3.256v3.785h3.25v9.135a12.962 12.962 0 0 1-7.94-4.526 13.138 13.138 0 0 1-3.025-8.671 13.13 13.13 0 0 1 3.386-8.534 12.953 12.953 0 0 1 8.123-4.184C27.36 7.617 34 13.773 34 21.488Z"
        fill="#fff"
      />
    </IconBase>
  )
}

Icon.displayName = 'IconFacebook'

export const IconFacebook = memo<IconProps>(themed(Icon))
