import { YStackProps, YStack, Stack } from '@mythical/ui'
import { useSafeArea } from 'app/provider/safe-area/use-safe-area'
import React from 'react'
import { Dimensions } from 'react-native'
import { ScrollView } from './components/ScrollView'
type FullScreenModalProps = {
  children: React.ReactNode
  offset?: number // offset from top of screen
  containerProps?: YStackProps
  onOverlayPress?: () => void
} & YStackProps

/**
 * Full screen modal with scrollable content.
 * Should be wrapped in a AnimatedPresence for animation to work
 */

const FullScreenModal = ({
  children,
  offset = 64,
  containerProps,
  onOverlayPress,
  ...props
}: FullScreenModalProps) => {
  const insets = useSafeArea()
  const { height } = Dimensions.get('window')
  const availableScreenHeight = height - insets.top

  return (
    <YStack
      position="absolute"
      zIndex={200000}
      top={insets.top + offset}
      bottom={0}
      width="100%"
      h="100%"
      opacity={1}
      minHeight={availableScreenHeight - offset}
      enterStyle={{
        opacity: 1,
      }}
      exitStyle={{
        opacity: 0,
      }}
      animation={[
        'quick',
        {
          opacity: {
            overshootClamping: true,
          },
        },
      ]}
      {...props}
    >
      <Stack
        w="100%"
        h="100%"
        bg="black"
        opacity={0.33}
        position="absolute"
        top={0}
        left={0}
        right={0}
        onPress={onOverlayPress}
      />
      <YStack h="100%" w="100%" flexGrow={1} {...containerProps}>
        <ScrollView
          minHeight={availableScreenHeight - offset}
          h="100%"
          className="overflow-y-auto"
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          {children}
        </ScrollView>
      </YStack>
    </YStack>
  )
}

export default FullScreenModal
