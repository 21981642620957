import {
  getAnalytics,
  initializeAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'

import app from './firebase.web'
import { useMemo } from 'react'

let instance

if (typeof window !== 'undefined') {
  instance = initializeAnalytics(app, {
    config: {
      send_page_view: false,
    },
  })
}

export default function setupAnalytics() {
  const item = useMemo(() => ({
    logEvent: (name: string, params: Record<string, any>) => {
      logEvent(instance, name, params)
    },
    logUser: (userId: string, tier: string) => {
      setUserId(instance, userId)
      setUserProperties(instance, {
        tier,
      })
    },
  }), [])

  return item
}
