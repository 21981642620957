import React, { memo } from 'react'
import { Path, Circle as _Circle, Rect } from 'react-native-svg'

import { IconBase } from '../IconBase'
import { IconProps } from '../IconProps'
import { themed } from '../themed'

const Icon = (props) => {
  const { size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 42 43"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="M31.338 16.107c.016.229.016.458.016.69 0 7.048-5.366 15.177-15.177 15.177-2.9.003-5.738-.829-8.177-2.396a10.677 10.677 0 0 0 7.895-2.21 5.34 5.34 0 0 1-4.984-3.705 5.312 5.312 0 0 0 2.408-.091 5.336 5.336 0 0 1-4.275-5.229v-.062a5.295 5.295 0 0 0 2.42.668 5.34 5.34 0 0 1-1.654-7.13 15.138 15.138 0 0 0 10.995 5.57 5.338 5.338 0 0 1 9.09-4.865 10.675 10.675 0 0 0 3.386-1.294 5.347 5.347 0 0 1-2.34 2.95A10.598 10.598 0 0 0 34 13.343a10.825 10.825 0 0 1-2.662 2.764Z"
        fill="#fff"
      />
    </IconBase>
  )
}

Icon.displayName = 'IconTwitter'

export const IconTwitter = memo<IconProps>(themed(Icon))
