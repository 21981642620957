import { Image, Stack, YStack, YStackProps } from '@mythical/ui'
import React from 'react'

const imageMap = {
  Initiate: require('app/assets/images/avatar-frame-tier-0.png'),
  '1st Degree': require('app/assets/images/avatar-frame-tier-1.png'),
  '2nd Degree': require('app/assets/images/avatar-frame-tier-2.png'),
  '3rd Degree': require('app/assets/images/avatar-frame-tier-3.png'),
}

const simpleImageMap = {
  Initiate: require('app/assets/images/avatar-frame-tier-0--simple.png'),
  '1st Degree': require('app/assets/images/avatar-frame-tier-1--simple.png'),
  '2nd Degree': require('app/assets/images/avatar-frame-tier-2--simple.png'),
  '3rd Degree': require('app/assets/images/avatar-frame-tier-3--simple.png'),
}

interface AvatarProps extends YStackProps {
  src?: string
  tier?: keyof typeof imageMap | string
  simple?: boolean
}

export const Avatar = ({
  src,
  tier = 'Initiate',
  simple,
  ...props
}: AvatarProps) => {
  const avatarStyle = simple ? simpleImageMap[tier] : imageMap[tier]
  const image = avatarStyle || imageMap.Initiate

  return (
    <YStack w={50} h={50} {...props} pos="relative">
      <Stack
        pos="absolute"
        ai="center"
        jc="center"
        width="100%"
        height="100%"
        top="10%"
      >
        <Stack
          ai="center"
          jc="center"
          width="70%"
          height="70%"
          overflow="hidden"
          borderRadius={10000}
          top="-5%"
        >
          <Image
            source={{
              uri:
                src || require('app/assets/images/profile-image--default.png'),
            }}
            width="100%"
            height="100%"
            resizeMode="cover"
          />
        </Stack>
        <Image
          pos="absolute"
          top="-10%"
          style={{ width: '100%', height: '100%' }}
          source={{ uri: image }}
        />
      </Stack>
    </YStack>
  )
}
