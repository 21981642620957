import {
  Anchor,
  Image,
  SizableText,
  ThemeName,
  XStack,
  YStack,
  isWeb,
  Paragraph,
} from '@mythical/ui'
import { ButtonLink } from 'app/components/primitive/Button'
import {
  IconFacebook,
  IconInstagram,
  IconSnapchat,
  IconTwitter,
  IconYoutube,
} from 'app/components/primitive/Icon'
import { SrOnly } from 'app/components/primitive/SrOnly'
import Version from 'app/components/primitive/Version'
import { isAndroid, isIOS } from 'app/utils/constants'
import Constants from 'expo-constants'
import React from 'react'

const links = [
  [
    {
      label: 'Join the community',
      href: '/perks',
      theme: 'primary',
      hidden: isIOS,
    },
    {
      label: 'Perks',
      href: '/perks',
      hidden: isIOS,
    },
    {
      label: 'FAQ',
      href: '/pages/faqs',
    },
    {
      label: 'Contact',
      href: '/contact',
    },
  ],
  [
    {
      label: 'Terms of Use',
      href: '/pages/terms',
    },
    {
      label: 'Privacy Policy',
      href: '/pages/privacy',
    },
    {
      label: 'Guidelines',
      href: '/pages/guidelines',
    },
    {
      label: 'Update Your Cookie Preferences',
      href: '/cookies',
      hidden: !isWeb,
    },
  ],
]

const socialLinks = [
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/mythicalsociety',
    icon: IconFacebook,
  },
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/mythicalsociety/',
    icon: IconInstagram,
  },
  {
    label: 'Snapchat',
    href: 'https://story.snapchat.com/p/49b3f6cf-e9e2-492a-aec3-ef66c64d5133/464220642981888',
    icon: IconSnapchat,
  },
  {
    label: 'Twitter',
    href: 'https://twitter.com/mythicalsociety',
    icon: IconTwitter,
  },
  {
    label: 'Youtube',
    href: 'https://www.youtube.com/channel/UC4PooiX37Pld1T8J5SYT-SQ',
    icon: IconYoutube,
  },
]

const Footer = () => {
  return (
    <YStack w="100%" flexGrow={0} pt="$6" pb="$7" bg="$secondary10">
      <YStack
        space="$3"
        maxWidth={768}
        mx="auto"
        w="100%"
        jc="center"
        ai="center"
        {...(!isWeb && { mb: '$8' })}
      >
        <Image
          height={100}
          width="100%"
          source={require('app/assets/images/logo--full.png')}
          resizeMode="contain"
          style={{ height: 100, width: '100%' }}
        />
        <YStack jc="center" ai="center" space="$3">
          <SizableText
            fontFamily="$heading"
            size="$6"
            textTransform="uppercase"
            color="$primary10"
            {...(isAndroid && { pb: '$3' })}
          >
            Follow us!
          </SizableText>
          <XStack space="$2">
            {socialLinks.map((link) => (
              <Anchor
                key={link.href}
                href={link.href}
                target="_blank"
                animation="quick"
                accessibilityLabel={link.label}
                scale={1}
                hoverStyle={{
                  scale: 1.1,
                }}
                pressStyle={{
                  scale: 0.9,
                }}
              >
                <link.icon color="$primary1" size={42} />
                {isWeb && <SrOnly>{link.label}</SrOnly>}
              </Anchor>
            ))}
          </XStack>
        </YStack>
        <YStack space="$2" w="100%" jc="center" ai="center">
          {links.map((linkGroup, index) => (
            <XStack key={index} jc="center" ai="center" flexWrap="wrap">
              {linkGroup
                .filter((link) => !link.hidden)
                .map((link) => (
                  <ButtonLink
                    m="$2"
                    theme={(link.theme as ThemeName) || 'secondary10'}
                    key={link.label}
                    href={link.href}
                    $xs={{
                      px: '$2',
                      h: 25,
                      fontSize: '$2',
                      m: '$1.5',
                    }}
                  >
                    {link.label}
                  </ButtonLink>
                ))}
            </XStack>
          ))}
        </YStack>
      </YStack>
    </YStack>
  )
}

export default Footer
