import {
  isWeb,
  Anchor,
  H1,
  H2,
  H3,
  Paragraph as BaseParagraph,
  ParagraphProps,
  SizableText,
  Stack,
  YStack,
  YStackProps,
} from '@mythical/ui'
import React, { Fragment } from 'react'
import { Linking } from 'react-native'
import { Text as TextNode } from 'slate'
import { useLink } from 'solito/link'

interface Node {
  type: string
  children: Node[]
  [key: string]: any
}

export interface RichTextProps {
  content: any
  color?: string
  textProps?: ParagraphProps
  containerProps?: YStackProps
}

const TextLink = ({ node, color, textProps, serialize }) => {
  const href = node.url || ''
  const link = useLink({ href })

  const onPress = async (e) => {
    e.preventDefault()

    if (!href.startsWith('http') && !href.startsWith('/api')) {
      return link.onPress()
    }

    if (isWeb) {
      return window.open(href, href.startsWith('/') ? '_self' : '_blank')
    } else {
      Linking.openURL(href)
    }
  }

  return (
    <Anchor
      href={href}
      textDecorationLine="underline"
      hoverStyle={{
        textDecorationLine: "underline"
      }}
      {...(node.url?.startsWith('http') && { target: '_blank' })}
      {...textProps}
      onPress={onPress}
    >
      {serialize(node.children, color, false, {
        textDecorationLine: 'underline',
        fontWeight: '600',
        cursor: 'pointer',
        ...textProps,
      })}
    </Anchor>
  )
}

export default function RichText({
  content,
  color = '$primary1',
  textProps,
  containerProps,
}: RichTextProps) {
  let headerNumber = 0

  const serialize = (
    children: Node[],
    color: string,
    isOrdered?: boolean,
    textProps?: ParagraphProps
  ) => {
    const Paragraph = (props: ParagraphProps) => (
      <BaseParagraph {...props} color={color} {...textProps} />
    )

    return children.map((node, i) => {
      if (TextNode.isText(node)) {
        let text = <Paragraph key={i}>{node.text}</Paragraph>

        if (node.bold) {
          text = (
            <Paragraph fontWeight="bold" key={i}>
              {node.text}
            </Paragraph>
          )
        }

        if (node.code) {
          text = (
            <Paragraph fontFamily="monospace" key={i}>
              {node.text}
            </Paragraph>
          )
        }

        if (node.italic) {
          text = (
            <Paragraph fontStyle="italic" key={i}>
              {node.text}
            </Paragraph>
          )
        }

        if (node.underline) {
          text = (
            <Paragraph textDecorationLine="underline" key={i}>
              {node.text}
            </Paragraph>
          )
        }

        if (node.strikethrough) {
          text = (
            <Paragraph textDecorationLine="line-through" key={i}>
              {node.text}
            </Paragraph>
          )
        }

        return <Fragment key={i}>{text}</Fragment>
      }

      if (!node) {
        return null
      }

      switch (node.type) {
        case 'h1':
        case 'h2':
        case 'h3':
          const levels = {
            h1: H1,
            h2: H2,
            h3: H3,
          }
          const Component = levels[node.type]
          // get all text within the header
          const text = (node.children || []).reduce((text, child) => {
            if (TextNode.isText(child)) {
              return text + child.text
            }
            return text
          }, '')

          // create a slug from the first word of the header
          const slug =
            text
              .split(' ')?.[0]
              ?.toLowerCase()
              .replace(/[^\w]+/g, '-') || 'header'

          headerNumber++
          return (
            <Anchor href={`#${headerNumber}-${slug}`}>
              <Component
                key={i}
                my="$4"
                {...textProps}
                id={`${headerNumber}-${slug}`}
              >
                {serialize(node.children, color)}
              </Component>
            </Anchor>
          )
        case 'indent':
          return (
            <Stack
              borderLeftWidth={2}
              borderLeftColor="$secondary6"
              paddingLeft="$3"
              key={i}
            >
              {serialize(node.children, color)}
            </Stack>
          )
        case 'center': {
          return (
            <Stack alignItems="center" justifyContent="center" key={i}>
              {serialize(node.children, color, false, {
                textAlign: 'center',
              })}
            </Stack>
          )
        }
        case 'ul':
          return <YStack>{serialize(node.children, color)}</YStack>
        case 'ol':
          return <YStack>{serialize(node.children, color, true)}</YStack>
        case 'li':
          return (
            <Paragraph key={i} marginLeft={4}>
              {isOrdered ? `${i + 1}. ` : '• '}
              {serialize(node.children, color, isOrdered)}
            </Paragraph>
          )
        case 'link':
          return (
            <TextLink
              key={i}
              node={node}
              color={color}
              textProps={textProps}
              serialize={serialize}
            />
          )

        default:
          return (
            <SizableText key={i} my={0.5} {...textProps}>
              {serialize(node.children, color, false, textProps)}
            </SizableText>
          )
      }
    })
  }

  return (
    <YStack space={4} {...containerProps}>
      {serialize(content, color, false, textProps)}
    </YStack>
  )
}
