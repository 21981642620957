import IConfig from 'app/config/Config'
import createGraphqlClient from 'app/lib/graphql-client-creator'

import { UserStore } from '../store/user-store'
import { accessTokenExpired } from '../store/user-store/lib/token-expiry-determiner'

import * as Sentry from '@sentry/nextjs'

async function makeSiteGraphqlRequest<Type = any>(
  config: IConfig,
  query: string,
  variables: any,
  options: {
    userStore?: UserStore
    formatFn?: (data: any) => Type
    jwtToken?: string
  } = {}
): Promise<Type> {
  const { userStore, formatFn, jwtToken } = options

  let store: Partial<UserStore> | undefined = userStore
  if (userStore && accessTokenExpired(userStore) && !jwtToken) {
    store = (await userStore.refreshAccessToken()) as Partial<UserStore>
  }

  let url = config.cmsUrl

  // if nextjs client side, use the api route
  if (typeof window !== 'undefined') {
    url = config.webCmsUrl || config.cmsUrl
  }

  const client = createGraphqlClient(url, 'POST', store, jwtToken, !!jwtToken)

  try {
    const abortController = new AbortController()

    setTimeout(() => {
      abortController.abort()
    }, config.timeout)

    const data = await client.request({
      document: query,
      variables,
      signal: abortController.signal,
    })

    if (formatFn) {
      return formatFn(data)
    }

    return data as Type
  } catch (error) {
    Sentry.setExtra('url', url)
    Sentry.setExtra('query', query)
    Sentry.setExtra('variables', variables)
    console.error('Error making request')
    throw error
  }
}

export default makeSiteGraphqlRequest
